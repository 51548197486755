<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <div class="container">
          <div class="row">
            <div class="col-md-2" v-for="property of properties" :key="'P' + property.id">
              <CCard>
                <CCardHeader>
                  <span>{{ property.name }}</span>
                </CCardHeader>
                <CCardBody>
                  <CIcon name="cil-building" size="custom" />
                </CCardBody>
              </CCard>
            </div>
          </div>
        </div>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import viewMixin from '@/app/views/_mixins/view-mixin'

export default {
  name: 'PropertiesMenu',
  mixins: [viewMixin],
  data: () => {
    return {
      properties: [],
      lists: {},
      helpers: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const self = this
      self.$http
        .get('admin/properties', { _lists: 'property_types,managers' })
        .then(response => {
          self.properties = response.data.properties
          self.lists = response.data._lists || []
          self.prepareHelpers(self.lists)
        })
        .catch(error => {
          console.error(error)
          //self.$router.push({ path: '/login' })
        })
    },
    // TODO: Move to a function
    prepareHelpers(lists) {
      this.helpers = { statuses: { 0: { name: 'Inactive', color: 'secondary' }, 1: { name: 'Active', color: 'success' } } }
      for (const list in lists) {
        this.helpers[list] = {}
        for (const item of lists[list]) {
          this.helpers[list][item.id] = item
        }
      }
    },

    onDataChanged(alert) {
      console.log('onDataChanged', alert)
    }
  }
}
</script>

<style lang="scss">
.flex-card {
  flex: 1 1 0px;
}
</style>
